import React from "react";
import styled from "styled-components";
import { JobOfferInterface } from "../../graphql/jobs";
import JobOfferTile from "../../components/job-offer/job-offer-tile";

interface OffersListProps {
  jobs: Array<JobOfferInterface>;
  header: string | React.ReactNode;
  onApply: (offerId: string, jobTitle: string) => void;
}

interface HeadlineProps {
  length: number;
}

const OffersList = React.forwardRef<HTMLDivElement, OffersListProps>(({ jobs, header, onApply }, ref) => {
  return (
    <Wrapper ref={ref}>
      <HeadlineWrapper length={jobs.length}>
        <Headline>{header}</Headline>
        <SubHeadline>{jobs.length} jobs</SubHeadline>
      </HeadlineWrapper>
      <Grid>
        {jobs.map((jobOffer, index) => (
          <OfferItemWrapper key={`job_offer_${index}`}>
            <JobOfferTile jobOffer={jobOffer} onApply={onApply} />
          </OfferItemWrapper>
        ))}
      </Grid>
    </Wrapper>
  );
});

const Wrapper = styled.div.attrs({
  className: "even:bg-gray2 px-5 py-14 tablet:px-11 tablet:pt-32 tablet:pb-20 jobsSection",
})``;

const Grid = styled.div.attrs({
  className: "grid tablet:grid-cols-12 gap-4",
})``;
const HeadlineWrapper = styled.div.attrs<HeadlineProps>(({ length }) => ({
  className: `text-center tablet:text-left px-5 ${length === 0 ? "pb-0" : "pb-10"}`,
}))<HeadlineProps>``;
const SubHeadline = styled.div.attrs({
  className: "font-light text-gray5 text-paragraphTablet",
})``;
const Headline = styled.h2.attrs({
  className: "font-light text-subHeadlineMobile tablet:text-5xl mb-2 text-white",
})``;
const OfferItemWrapper = styled.div.attrs({
  className: "tablet:col-span-4",
})``;

export default OffersList;
