import React from "react";
import { useMatchMedia } from "./use-match-media";

export function useSimpleScrollspy(containerClassName: string, activeClassName: string = 'activeSection') {
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = React.useState<boolean>();

  React.useEffect(() => {
    setIsMobile(matchMobile);
  }, [matchMobile]);

  React.useEffect(() => {
    const elementsAll = document.querySelectorAll("[data-nav-item]") as NodeListOf<HTMLDivElement>;
    const navSectionAll = document.querySelectorAll(`.${containerClassName}`) as NodeListOf<HTMLDivElement>;

    const removeActiveClass = () => {
      elementsAll.forEach((element) => element.classList.remove(activeClassName));
    };

    if (isMobile) {
      removeActiveClass();
      return;
    }

    function activeMenu() {
      if (window.scrollY + 10 >= navSectionAll[0]?.offsetTop) {
        let len = navSectionAll.length;
        while (--len && window.scrollY + 20 < navSectionAll[len].offsetTop) {}
        removeActiveClass();
        elementsAll[len].classList.add(activeClassName);
      } else {
        removeActiveClass();
      }
    }

    if (window.scrollY + 10 >= navSectionAll[0].offsetTop) {
      activeMenu();
    }

    window.addEventListener("scroll", activeMenu);

    return () => {
      window.removeEventListener("scroll", activeMenu);
    };
  }, [isMobile, containerClassName, activeClassName]);
}
