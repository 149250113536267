import React from "react";
import styled from "styled-components";
import { BackgroundX } from "../../components/background-x";
import { PimcoreImage } from "../../components/pimcore-image";
import { Headline2 } from "../../components/typography";
import { JobsPageContentInterface } from "../../graphql/jobs-page";

interface InfoSectionProps {
  content: JobsPageContentInterface;
}

const InfoSection: React.FC<InfoSectionProps> = ({ content }) => {
  return (
    <Wrapper>
      <BackgroundX />
      <Grid>
        <MediaCell1>
          <PimcoreImage image={content.bottomSectionBackground} mobileImage={content.bottomSectionBackgroundMobile} />
        </MediaCell1>
        <MediaCell2>
          <HeadlineWrapper>
            <Headline2 dangerouslySetInnerHTML={{ __html: content.bottomSectionHeadline }} />
          </HeadlineWrapper>
          <ParagraphWrapper>
            <Paragraph dangerouslySetInnerHTML={{ __html: content.bottomSectionSubheadline }} />
          </ParagraphWrapper>
          <Content dangerouslySetInnerHTML={{ __html: content.bottomSectionContent }} />
        </MediaCell2>
      </Grid>
    </Wrapper>
  );
};

export default InfoSection;

const Wrapper = styled.div.attrs({ className: "relative overflow-hidden" })``;

const Grid = styled.div.attrs({
  className: "grid gap-4 tablet:grid-cols-12",
})``;
const MediaCell1 = styled.div.attrs({
  className: "col-start-1 col-span-12 row-start-1",
})``;
const MediaCell2 = styled.div.attrs({
  className:
    "px-5 tablet:px-0 col-start-1 col-span-12 tablet:col-start-2 tablet:col-span-5 row-start-1 mt-28 mb-20",
})``;
const Content = styled.div.attrs({
  className: "font-light text-lg text-gray8 tablet:text-xl text-white",
})``;
const HeadlineWrapper = styled.div.attrs({
  className: "pr-24 tablet:pr-10",
})``;
const Paragraph = styled.div.attrs({
  className: "font-light text-paragraphTablet text-white",
})``;
const ParagraphWrapper = styled.div.attrs({ className: "mb-8" })``;
