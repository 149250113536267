import React from "react";
import styled from "styled-components";
import GatsbyLink from "gatsby-link";
import { theme } from "../../styles/styles";
import { JobOfferInterface } from "../../graphql/jobs";

interface JobOfferTileProps {
  jobOffer: JobOfferInterface;
  onApply: (offerId: string, jobTitle: string) => void;
  size?: "fixed" | "fixed-height" | "auto";
}

interface CommonDivProps {
  size: string;
}

interface WrapperProps extends CommonDivProps {
  bg: string;
  size: string;
}

const JobOfferTile: React.FC<JobOfferTileProps> = ({ jobOffer, onApply, size = "auto" }) => {
  const applyButtonRef = React.createRef<HTMLAnchorElement>();
  const offerButtonRef = React.useRef<any>(null);

  const onApplyClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onApply(jobOffer.jobOfferId, jobOffer.title);
  };

  const handleFullClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (e.target !== applyButtonRef.current) {
      offerButtonRef?.current?.click();
    }
  };

  return (
    <Wrapper bg={jobOffer.color} size={size} onClick={handleFullClick}>
      <HeadlineWrapper>
        <HeadlineOffer>{jobOffer.title}</HeadlineOffer>
        <SubHeadlineOffer>{jobOffer.location}</SubHeadlineOffer>
      </HeadlineWrapper>
      <DateWrapper size={size}>{jobOffer.endDate ? `apply until ${jobOffer.endDate}` : ""}</DateWrapper>
      <ButtonsWrapper size={size}>
        <InfoButton ref={offerButtonRef} to={`/jobs/${jobOffer.slug}/`}>
          Info
        </InfoButton>
        <ApplyButton ref={applyButtonRef} href={"#"} onClick={onApplyClick}>
          Apply
        </ApplyButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs<WrapperProps>(({ bg }) => ({
  className: `py-6 px-7 rounded-lg h-full grid grid-cols-2 justify-between backdrop-blur-md border-2 border-transparent cursor-pointer tablet:hover:border-yellow transition-colors ${
    "red" === bg ? "bg-red2/50" : "bg-gray6"
  } `,
}))<WrapperProps>`
  grid-template-rows: 1fr auto auto;
  min-height: ${({ size }) => ("fixed" === size || "fixed-height" === size ? "20rem" : "auto")};
  width: ${({ size }) => ("fixed" === size ? "18.5rem" : "auto")};

  @media ${theme.mediaQueries.tablet} {
    min-height: 18rem;
    width: auto;
  }
`;

const HeadlineWrapper = styled.div.attrs({
  className: "col-start-1 col-span-2 tablet:row-span-2 pb-4 tablet:pb-6",
})``;

const DateWrapper = styled.div.attrs<CommonDivProps>(({ size }) => ({
  className: `col-span-2 font-bold text-sm text-gray5 pt-4 tablet:pt-6 border-t border-white/10${
    "auto" === size ? " tablet:flex tablet:items-center tablet:col-span-1 tablet:order-1 tablet:justify-end" : ""
  }`,
}))<CommonDivProps>``;

const ButtonsWrapper = styled.div.attrs<CommonDivProps>(({ size }) => ({
  className: `col-span-2 tablet:col-span-1 flex items-center pt-4 tablet:pt-6 ${
    "auto" === size ? " tablet:border-t tablet:border-white/10" : ""
  }`,
}))<CommonDivProps>``;

const HeadlineOffer = styled.h3.attrs({
  className: "font-bold text-paragraphTablet text-white mb-3",
})``;

const SubHeadlineOffer = styled.div.attrs({
  className: "font-light text-xl tablet:text-lg text-gray5",
})``;

const ApplyButton = styled.a.attrs({
  className:
    "inline-block px-6 py-3.5 tablet:px-7 border-solid border-2 border-yellow text-yellow hover:bg-yellow font-bold rounded-full bg-transparent mr-2.5 hover:text-gray transition-colors",
})``;

const InfoButton = styled(GatsbyLink).attrs({
  className:
    "inline-block px-6 py-3.5 tablet:px-7 border-solid border-2 border-white text-white hover:bg-white font-bold rounded-full bg-transparent mr-2.5 hover:text-gray transition-colors",
})``;

export default JobOfferTile;
